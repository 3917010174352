<template>
  <div>
    <a-page-header :title="$route.query.look?'查看专题信息':$route.query.id?'修改专题':'新增专题'" @back="() => $router.go(-1)" />

    <a-row>
      <a-col :span="14">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
          <a-form-item label="标题">
            <a-input v-model="addFromData.title" />
          </a-form-item>
          <a-form-item label="选择企业">
            <CompanySelect
              style="width:200px"
              @CompanySelect="CompanySelect"
              :serchKeyword="addFromData.company_name"
              :selectId="addFromData.company_id"
              :disabled="this.$route.query.id ? true : false"
            ></CompanySelect>
          </a-form-item>

          <a-form-item label="病例">
            <a-select
              @focus="caseFocus"
              :disabled="addFromData.company_id == undefined"
              mode="multiple"
              label-in-value
              :value="value"
              placeholder="选择病例"
              style="width: 100%"
              :filter-option="false"
              @search="fetchUser"
              @change="handleChangeSelect"
            >
              <a-select-option v-for="d in data" :key="d.id">
                {{ d.title }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="文章">
            <a-select
              @focus="artFocus"
              :disabled="addFromData.company_id == undefined"
              mode="multiple"
              label-in-value
              :value="value2"
              placeholder="选择文章"
              style="width: 100%"
              :filter-option="false"
              @search="fetchUser2"
              @change="handleChangeSelect2"
            >
              <a-select-option v-for="d in data2" :key="d.id">
                {{ d.post_title }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="内容">
            <UE
              :sentContent="addFromData.content"
              @getUeContent="getUeContent"
            ></UE>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="10">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="封面">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadImage"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                style="display:block;max-width:100px;max-height:100px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="状态">
            <a-radio-group v-model="addFromData.status">
              <a-radio :value="1">
                上线
              </a-radio>
              <a-radio :value="0">
                下线
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="是否推荐">
            <a-radio-group v-model="addFromData.recommended">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item v-if="$route.query.look!=1">
            <a-button
              type="primary"
              :style="{ marginLeft: '150px' }"
              @click="handleOk"
            >
              保存
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import UE from "@/components/UE/UE.vue";
import { watch } from "fs";

import {
  uploadImage,
  specialDataSave,
  specialDetail,
  casesShowList,
  articleShowList,
} from "@/apis/index";

export default {
  data() {
    return {
      addFromData: {
        id: "",
        title: "",
        img_id: "",
        content: "",
        status: 1,
        recommended: 0,
        article_ids: [],
        case_ids: [],
        company_id: undefined,
        company_name: "",
      },
      loading: false,
      imageUrl: "",
      uploadImage,
      //   下拉
      data: [],
      value: [],

      data2: [],
      value2: [],

      caseFousFlag: true,
      artFousFlag: true,
    };
  },
  components: {
    UE,
  },
  watch: {
    value(val) {
      this.addFromData.case_ids = [];
      if (val.length > 0) {
        val.forEach((item) => {
          this.addFromData.case_ids.push(item.key);
        });
      } else {
        this.addFromData.case_ids = [];
      }
    },
    value2(val) {
      this.addFromData.article_ids = [];
      if (val.length > 0) {
        val.forEach((item) => {
          this.addFromData.article_ids.push(item.key);
        });
      } else {
        this.addFromData.article_ids = [];
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      specialDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.addFromData = { ...res.data };
          res.data.case_list.forEach((item) => {
            this.value.push({
              key: item.id,
              label: item.title,
            });
          });
          res.data.article_list.forEach((item) => {
            this.value2.push({
              key: item.id,
              label: item.post_title,
            });
          });
          this.imageUrl = res.data.img_url;
          this.addFromData.company_name = res.data.company.company_name;
        }
      });
    }
  },
  computed: {},
  methods: {
    caseFocus() {
      if (this.caseFousFlag) {
        this.caseFousFlag = false;
        this.getCasesShowList();
      }
    },
    CompanySelect(val) {
      this.caseFousFlag = true;
      this.artFousFlag = true;
      this.addFromData.company_id = val;
      this.value = [];
      this.value2 = [];
    },
    fetchUser(value) {
      this.getCasesShowList(value);
    },
    handleChangeSelect(value) {
      this.value = value;
    },
    handleOk() {
      specialDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$router.push({ path: "/contents/Special" });
          this.$message.success("success");
        }
      });
    },
    getArticleShowList(keyword = "") {
      articleShowList({
        page: 1,
        limit: 50,
        keyword,
        company_id: this.addFromData.company_id,
      }).then((res) => {
        if (res.code == 1) {
          this.data2 = res.data.list;
        }
      });
    },
    artFocus() {
      if (this.artFousFlag) {
        this.artFousFlag = false;
        this.getArticleShowList();
      }
    },
    fetchUser2(value) {
      this.getArticleShowList(value);
    },
    handleChangeSelect2(value) {
      this.value2 = value;
    },
    getCasesShowList(keyword = "") {
      casesShowList({
        page: 1,
        limit: 50,
        keyword,
        company_id: this.addFromData.company_id,
      }).then((res) => {
        if (res.code == 1) {
          this.data = res.data.list;
        }
      });
    },
    getUeContent(val) {
      this.addFromData.content = val;
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imageUrl = info.file.response.data.url;
        this.addFromData.img_id = info.file.response.data.id;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped></style>
